<template>
    <div class="invitPage">
        <div class="ipBox">
            <!-- 标题 -->
            <div class="ipbTitle">
                <p class="itBig">推荐好友领现金红包</p>
                <p class="itSmall">邀得多领的多，红包不上限</p>
            </div>
            <!-- 收集 -->
            <div class="form" @keydown.enter="getFrom">
                <div>
                    <div>推荐岗位</div>
                    <div>
                        <input type="text" readonly v-model="form.industry" @click="show(1)" />
                        <div class="i" @click="show(1)">
                            <div class="downIcon" :class="isShow === 1 ? 'rotate' : ''">
                                <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                            </div>
                        </div>
                        <div class="xiala" v-show="isShow === 1">
                            <div
                                v-for="el in workList"
                                :key="el.name"
                                :style="{ height: el.he ? `${30 * ((el.children ? el.children.length : 0) + 1)}px` : '30px' }"
                            >
                                <p @click="el.he = !el.he">
                                    <span>{{ el.name }}</span>
                                </p>
                                <!-- @click="select('work', el)" -->
                                <div v-for="e in el.children" :key="e.name" @click="select('industry', el.name + ' - ' + e.name)">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ e.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>推荐人姓名</div>
                    <div>
                        <input type="text" v-model="form.name" />
                        <span>与账号一致的真实姓名，仅用于发红包奖励</span>
                    </div>
                </div>
                <div>
                    <div>推荐人返现账号</div>
                    <div>
                        <input type="text" @input="shibie" v-model.trim="form.account" style="width: 70%; border-radius: 0px" />
                        <input
                            type="text"
                            readonly
                            v-model="form.account_type"
                            style="width: 30%; text-align: left"
                            @click="show(2)"
                        />
                        <div class="i" @click="show(2)">
                            <!-- <i class="el-icon-arrow-down" :class="isShow === 2 ? 'rotate' : ''"></i> -->
                            <div class="downIcon" :class="isShow === 2 ? 'rotate' : ''">
                                <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                            </div>
                        </div>
                        <div class="xiala" v-show="isShow === 2">
                            <div v-for="el in typeList" :key="el" @click="select('account_type', el)" class="hover">{{ el }}</div>
                        </div>
                        <span>仅用于发红包奖励</span>
                    </div>
                </div>
                <div>
                    <div>更多信息</div>
                    <div>
                        <textarea v-model="form.more" cols="30" maxlength="300" rows="10" placeholder="其他内容"></textarea>
                    </div>
                </div>
                <div>
                    <div>上传附件</div>
                    <div style="text-align: left">
                        <!-- 神奇的代码 -->
                        <div style="display: inline-block; height: 100%" v-loading="loading">
                            <input
                                type="text"
                                placeholder="点击上传"
                                readonly
                                onclick="document.querySelector('#file>div').click()"
                                style="width: 120px"
                            />
                            <input
                                type="text"
                                @click="
                                    fileName = '还未上传';
                                    fileList = [];
                                    form.annex = '';
                                "
                                placeholder="删除"
                                readonly
                                style="width: 120px"
                            />
                        </div>
                        <span>{{ fileName }}</span>
                        <el-upload
                            id="file"
                            name="thumbnail"
                            :action="upLoadUrl + '/api/Project/upload'"
                            :on-success="success"
                            :before-upload="beforeUpload"
                            :on-error="error"
                            :show-file-list="false"
                            :limit="1"
                            accept=".jpg,.png,.doc,.docx,.pdf,.xls,.xlsx"
                            :file-list="fileList"
                            style="display: none"
                        >
                            上传
                        </el-upload>
                    </div>
                </div>
                <div class="btns">
                    <button class="btn1" @click="closeFrom">清空</button>
                    <button class="btn2" @click="getFrom" :class="{ no: status }">提交</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import bankCardAttribution from "@/util/bank";
import QueryString from "qs";

export default {
    name: "InvitPage",
    data() {
        return {
            form: {
                // 应聘岗位
                industry: "",
                // 推荐人姓名
                name: "",
                // 账号平台
                account_type: "",
                // 账号
                account: "",
                // 更多信息
                more: "",
                // 上传文件名
                annex: "",
                channel: "人才招聘",
            },
            // 第xx个下拉框
            isShow: null,
            workList: [
                {
                    name: "1级",
                    children: ["11", "33"],
                    he: false,
                },
                {
                    name: "2级",
                    children: ["11", "22", "33"],
                    he: false,
                },
                {
                    name: "3级",
                    children: ["11", "22", "33"],
                    he: false,
                },
            ],
            typeList: ["微信", "支付宝", "银联账号"],
            issb: false,
            upLoadUrl: "",
            fileName: "还未上传",
            fileList: [],
            loading: false,
        };
    },
    async created() {
        let { data } = await axios.get("/api/recruit/recruitShow");
        this.workList = data.map(e => {
            return {
                name: e.title,
                he: false,
                children: e.children.map(el => {
                    return {
                        name: el.title,
                    };
                }),
            };
        });
        this.upLoadUrl = axios.defaults.baseURL;
        // this.upLoadUrl = "https://vuapi.vu818.com";
    },
    methods: {
        show(num) {
            if (num === this.isShow) return (this.isShow = null);
            this.isShow = num;
        },
        select(name, val) {
            this.form[name] = val;
            this.isShow = null;
        },
        // 识别银行卡
        shibie() {
            let p = /^\d+$/;
            if (p.test(this.form.account)) {
                // 为数字
                let info = bankCardAttribution(this.form.account);
                console.log(info);
                if (info != "error") {
                    this.form.account_type = info.bankName;
                    this.issb = true;
                    return;
                }
            }
            if (this.issb) {
                this.issb = false;
                this.form.account_type = "";
            }
        },
        // 成功
        success(url, file) {
            // console.log(file);
            this.form.annex = axios.defaults.baseURL + url;
            this.fileName = file.name;
            this.loading = false;
        },
        // 失败
        error(err, file, fileList) {
            this.$message.error(err);
        },
        beforeUpload(file) {
            this.loading = true;
            // 上传限制
            const xz = file.size / 1024 / 1024 < 30;
            if (!xz) {
                this.$message.error("上传文件大小不能超过 30MB!");
                return false;
            }
            return true;
        },
        // 清空
        closeFrom() {
            this.form = {
                industry: "",
                name: "",
                account_type: "",
                account: "",
                more: "",
                annex: "",
                channel: "人才招聘",
            };
            this.fileName = "还未上传";
            this.fileList = [];
        },
        // 提交
        async getFrom() {
            if (this.status) return;
            let { form } = this;
            let { data } = await axios.post("/api/mail/add", QueryString.stringify(form));
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(`${data.msg}，恭喜你是今天第${data.count}位推荐者`);
                this.closeFrom();
            }
        },
    },
    computed: {
        status() {
            for (const k in this.form) {
                if (!this.form[k] && k != "annex") return true;
            }
            return false;
        },
    },
};
</script>

<style lang="less" scoped>
.invitPage {
    width: 100%;
    height: 700px;
    margin-top: 78px;
    overflow: hidden;
    .ipBox {
        max-width: 1280px;
        height: 650px;
        margin: 0 auto;
        margin-top: 50px;
        // 标题
        .ipbTitle {
            width: 100%;
            height: 80px;
            text-align: center;
            .itBig {
                width: 100%;
                height: 55px;
                line-height: 55px;
                font-size: 48px;
            }
            .itSmall {
                width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 22px;
            }
        }
    }
}
.form {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    & > div {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        & > div:nth-of-type(1) {
            width: 120px;
            height: 40px;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(90deg, rgb(119, 129, 241), rgba(119, 129, 241, 0.88));
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            color: white;
        }
        & > div:nth-of-type(2) {
            width: 600px;
            position: relative;
            & > span {
                position: absolute;
                left: 0px;
                top: 40px;
                font-size: 12px;
                color: #999;
            }
            & > .i {
                position: absolute;
                right: 20px;
                font-size: 20px;
                top: 50%;
                transform: translateY(-50%);
                .downIcon {
                    width: 20px;
                    height: 15px;
                    position: relative;
                    overflow: hidden;
                    transition: 0.5s;
                    img {
                        width: 646px;
                        top: -371px;
                        left: -68px;
                        position: absolute;
                    }
                }
            }
        }

        input,
        textarea {
            // width: 400px;
            width: 100%;
            height: 100%;
        }
        input {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 0 20px;
            border: none;
            box-shadow: rgb(220 220 220) 1px 1px 10px 1px;
            font-size: 15px;
            outline: none;
        }
        input[readonly] {
            cursor: pointer;
            text-align: center;
        }
        .xiala {
            width: 600px;
            // height: 200px;
            position: absolute;
            top: 50px;
            border-radius: 10px;
            background-color: rgb(255, 255, 255);
            box-shadow: rgb(220 220 220) 1px 1px 10px 1px;
            overflow: hidden;
            z-index: 6;
            & > div {
                width: 100%;
                height: auto;
                color: rgb(0, 0, 0);
                font-size: 14px;
                line-height: 30px;
                overflow: hidden;
                transition: 0.5s;
                cursor: pointer;
                p {
                    text-align: center;
                    height: 30px;
                    .hover();
                }
                & > div {
                    height: 30px;
                    .hover();
                }
            }
            .hover {
                transition: 0s;
                &:hover {
                    background-color: #dcdcdc;
                }
            }
        }
    }
    & > div:nth-of-type(4) {
        & > div {
            height: 150px;
            line-height: 150px;
        }
        textarea {
            padding: 10px 20px;
            height: 150px;
            resize: none;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: rgb(220 220 220) 1px 1px 10px 1px;
            border: none;
        }
    }
    .btns {
        position: absolute;
        bottom: -50px;
        right: 340px;
        .btn1,
        .btn2 {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-radius: 10px;
            font-size: 18px;
            cursor: pointer;
            background-color: white;
            border: none;
        }
        .btn2 {
            color: white;
            background: linear-gradient(90deg, #7781f1, rgba(119, 129, 241, 0.8784313725490196));
        }
    }
}
// 图标选择动画
.rotate {
    transform: rotate(180deg) !important;
}
.no {
    background: #ebeef5 !important;
    color: #c0c4cc !important;
    cursor: not-allowed !important;
}
</style>
